.App{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.lottie_json{
  width: 30%;
  margin-bottom: 2rem;
}



@media only screen and (max-width: 768px) {
  .App img{
    width: 90%;
  }
  .lottie_json{
    width: 90%;
    margin-bottom: 2rem;
  }
}